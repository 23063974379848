<template>
    <div>
        <!-- <b-card> -->
            <b-tabs 
                card
                nav-wrapper-class="nav-hidden" 
                align="left"
                class="custom-tab"
            >
                <b-tab>
                    <template #title>
                        <feather-icon icon="InfoIcon"/>
                        <span>Basic Information</span>
                    </template>
                    <div>
                        <b-card class="p-2 my-2">
                            <b-row class="pt-2">
                                <b-col  cols="12" class="mb-2 d-flex">
                                    <h5>Topic Name : </h5>
                                    <h5 class="ml-1" v-if="item.name">{{item.name}}</h5>
                                </b-col>
                                <b-col  cols="12" class="mb-2 d-flex">
                                    <h5>Module Name :  </h5>
                                    <span class="ml-1" v-if="item.module">{{item.module.name}}</span>
                                </b-col>
                                <b-col cols="12" class="mb-2">
                                    <h5>Description : </h5>
                                    <span v-if="item.description">{{item.description}}</span>
                                </b-col>
                            </b-row>
                        </b-card>
                    </div>
                </b-tab>
                <b-tab class="text-left">
                    <template #title>
                        <feather-icon icon="LayersIcon"/>
                        <span>Sub-Topics</span>
                    </template>
                    <div v-if="item.subTopics.length">
                        <b-card 
                            class="p-2 my-2"
                            v-for="(subTopic , index) in item.subTopics"
                            :key="index"
                        >
                        
                            <b-row class="mb-2">
                                <b-col md="12">
                                    <div class="d-flex justify-content-between">
                                        <h4 class="text-primary">{{subTopic.topic_type.name}}</h4>
                                        <b-link
                                            v-if="hasPermission('Teacher Edit Materials')"
                                            :tooltip="'none'"
                                            class="alert-link cutom-alert-link mr-1 btn "
                                            :to="{ name: 'edit_topic', params: { id: item.id} }"
                                        >
                                            Edit
                                        </b-link>
                                    </div>
                                </b-col>
                                <hr/>
                            </b-row>
                            <!-- {{ subTopic }} -->
                            <b-row class="w-100" v-if="subTopic && subTopic.type == 4" >
                                <b-col md="12">
                                    <img :src="item.subTopics[0].image" style="object-fit: cover;" alt="Image"  height="auto"  width="100%">
                                </b-col>
                            </b-row>
                            <b-row class="w-100" v-if="subTopic && subTopic.type == 5" >
                                <b-col md="12">
                                    <quill-editor
                                        v-model="item.subTopics[0].text"
                                        :options="bubbleOption"
                                        class="custom-quill"
                                        ref="quillEditor"
                                        :disabled="true"
                                    >
                                    </quill-editor>
                                </b-col>
                            </b-row>
                            <b-row class="w-100" v-if="subTopic && subTopic.type == 6" >
                                <b-col md="12">
                                    <embed 
                                        v-if="item.subTopics[0].pdf_path" 
                                        :src="item.subTopics[0].pdf_path" 
                                        type="application/pdf" 
                                        style="width: 100%; height: 600px;"
                                    />
                                </b-col>
                            </b-row>
                            <b-row class="w-100 d-flex justify-content-center" v-if="subTopic && subTopic.type == 7" >
                                <div  v-html="subTopic.ThingLink"></div>
                            </b-row>
                            <b-row class="w-100 d-flex justify-content-center" v-if="subTopic && subTopic.type == 8" >
                                <div  v-html="subTopic.Youtube"></div>
                            </b-row>
                            <b-row class="w-100" v-if="subTopic && subTopic.type == 3" >
                                <b-col md="12">
                                    <h5 class="mb-2">{{subTopic.open_questions}}</h5>
                                </b-col>
                            </b-row>
                            <b-row class="w-100" v-else>
                                <b-row class="mb-2 w-100" v-if="item.video_name">
                                    <b-col md="12" class="text-center">
                                        Video : {{ item.video_name }}
                                    </b-col>
                                </b-row>
                                <b-row class="w-100" v-if="item.video_name">
                                    <b-col md="3"></b-col>
                                    <b-col md="6">
                                        <div class="text-center">
                                            <video width="100%" height="auto" controls>
                                                <source :src="item.video_path" type="video/mp4">
                                                <source :src="item.video_path" type="video/ogg">
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                    </b-col>
                                    <b-col md="3"></b-col>
                                </b-row>
                                <b-row
                                    class="w-100"
                                    v-for="(question , index) in subTopic.MCQ"
                                    :key="index"
                                >
                                    <b-row
                                        class="w-100 px-2"
                                    >
                                        <b-col md="12">
                                            <h5 class="mb-2">{{question.question}}</h5>
                                        </b-col>
                                        <b-col md="12">
                                            <b-row>
                                                <!-- <b-col md="3">
                                                    Answer 2 (Correct Answer)
                                                </b-col> -->
                                                <b-col 
                                                    md="3" 
                                                    v-for="(answer, index) in question.options"
                                                    :key="index"
                                                >
                                                    {{answer.value}} {{ index == 0 ? ' (Correct Answer)' : '' }} 
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                    </b-row>
                                    <b-row class="w-100 pl-2">
                                        <b-col cols="12">
                                            <hr class="my-2">
                                        </b-col>
                                    </b-row>
                                </b-row>
                            </b-row>
                        </b-card>
                        
                    </div>
                </b-tab>
            </b-tabs>
        <!-- </b-card> -->
    </div>
</template>

<script>
import { mapGetters } from "vuex";
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
import { quillEditor } from 'vue-quill-editor'
import {
  BCard,
  BCol,
  BRow,
  BFormFile,
  BForm,
  BFormGroup,
  BButton,
  BFormInput,
  BFormDatepicker,
  BModal,
  BBadge,
  BTab,
  BTabs,
  BLink,
  BDropdown,
  BDropdownItem,
  BFormTextarea,
  validate,
} from "bootstrap-vue";

export default {
    name: "show",
    components: {
        BCard,
        BCol,
        BRow,
        BTab,
        BTabs,
        BLink,
        BFormFile,
        BForm,
        BFormGroup,
        BButton,
        BFormInput,
        BFormDatepicker,
        BModal,
        BBadge,
        BTab,
        BTabs,
        BLink,
        BDropdown,
        BDropdownItem,
        BFormTextarea,
        quillEditor
    },
    computed:{
    ...mapGetters({
      item: "topics/item",
    }),
    id(){
      return this.$route.params.id ? this.$route.params.id : null
    },
    module_id() {
        return this.$route.query.module_id
            ? this.$route.query.module_id 
            : null;
    },
  },
  data(){
    return{
        bubbleOption: {
            modules: {
                toolbar: false,
            },
            theme: 'bubble',
        },
    }
  },
  methods:{
      init(){
            this.$store.dispatch('topics/get', this.id).then(_=>{
            })
        },  
  },
  mounted(){
      this.init()
  }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/quill.scss';
</style>